


import React, { useState, useEffect, useCallback, useRef } from 'react';

// Constants for note mappings
const SEMITONE_TO_NOTE = {
  0: ['C', 'B#'],
  1: ['C#', 'Db'],
  2: ['D'],
  3: ['D#', 'Eb'],
  4: ['E', 'Fb'],
  5: ['F', 'E#'],
  6: ['F#', 'Gb'],
  7: ['G'],
  8: ['G#', 'Ab'],
  9: ['A'],
  10: ['A#', 'Bb'],
  11: ['B', 'Cb']
};

// Keyboard mapping for piano
const KEY_MAP = {
  'a': 0,  // C
  'w': 1,  // C#
  's': 2,  // D
  'e': 3,  // D#
  'd': 4,  // E
  'f': 5,  // F
  't': 6,  // F#
  'g': 7,  // G
  'y': 8,  // G#
  'h': 9,  // A
  'u': 10, // A#
  'j': 11  // B
};

const ArpeggioGenerator = () => {
  // Audio Context States
  const [audioContext, setAudioContext] = useState(null);
  const [masterGainNode, setMasterGainNode] = useState(null);
  const [analyser, setAnalyser] = useState(null);

  // Buffer States
  const [melodyBuffer, setMelodyBuffer] = useState(null);
  const [accompanimentBuffer, setAccompanimentBuffer] = useState(null);
  const [previewBuffer, setPreviewBuffer] = useState(null);

  // Note Sequence States
  const [melodyNotes, setMelodyNotes] = useState([0, 4, 7]);
  const [accompanimentNotes, setAccompanimentNotes] = useState([0, 4, 7]);
  const [activeNotes, setActiveNotes] = useState([]);

  // Playback Control States
  const [isPlaying, setIsPlaying] = useState(false);
  const [delay, setDelay] = useState(250);
  const [speed, setSpeed] = useState(1.0);
  const [volume, setVolume] = useState(0.5);
  const [repetitions, setRepetitions] = useState(1);
  const [playbackMode, setPlaybackMode] = useState('simultaneous');

  // MIDI States
  const [midiAccess, setMidiAccess] = useState(null);
  const [midiInputs, setMidiInputs] = useState([]);
  const [selectedMidiInput, setSelectedMidiInput] = useState('');

  // UI States
  const [errorMessage, setErrorMessage] = useState('');
  const [pianoMode, setPianoMode] = useState('melody');

  // Refs for tracking active sources and timeouts
  const activeSourcesRef = useRef({});
  const activeTimeoutsRef = useRef([]);
  const stopPlaybackTimeoutRef = useRef(null);
  const previewSourceRef = useRef(null);

  // Initialize Audio Context and MIDI
  useEffect(() => {
    const initAudio = async () => {
      try {
        const context = new (window.AudioContext || window.webkitAudioContext)({
          sampleRate: 48000,
          latencyHint: 'interactive',
        });

        const masterGain = context.createGain();
        const analyserNode = context.createAnalyser();

        masterGain.connect(analyserNode);
        analyserNode.connect(context.destination);
        masterGain.gain.setValueAtTime(volume, context.currentTime);

        analyserNode.fftSize = 2048;

        setAudioContext(context);
        setMasterGainNode(masterGain);
        setAnalyser(analyserNode);

        // Request MIDI access
        if (navigator.requestMIDIAccess) {
          const midiAccessObj = await navigator.requestMIDIAccess();
          handleMIDISuccess(midiAccessObj);
        }
      } catch (error) {
        console.error('Audio initialization error:', error);
        setErrorMessage('Failed to initialize audio system.');
      }
    };

    initAudio();

    return () => {
      stopAllAudio();
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  // MIDI handling
  const handleMIDISuccess = (midiAccessObj) => {
    setMidiAccess(midiAccessObj);
    const inputs = Array.from(midiAccessObj.inputs.values());
    setMidiInputs(inputs);
    if (inputs.length > 0) {
      setSelectedMidiInput(inputs[0].id);
    }
  };

  // Handle MIDI input selection
  useEffect(() => {
    if (midiAccess && selectedMidiInput) {
      const input = midiAccess.inputs.get(selectedMidiInput);
      if (input) {
        input.onmidimessage = handleMIDIMessage;
      }
    }
  }, [midiAccess, selectedMidiInput]);

  // Handle MIDI messages
  const handleMIDIMessage = async (message) => {
    const [command, note, velocity] = message.data;

    if (audioContext?.state === 'suspended') {
      await audioContext.resume();
    }

    const semitone = note % 12;

    if (command === 144 && velocity > 0) { // Note On
      playPreviewNote(semitone);
      if (pianoMode === 'melody') {
        setMelodyNotes(prev => [...prev, semitone]);
      } else {
        setAccompanimentNotes(prev => [...prev, semitone]);
      }
    }
  };

  // Audio file loading
  const loadAudioFile = useCallback(async (file, setBuffer) => {
    if (!audioContext || !file) return;

    try {
      const arrayBuffer = await file.arrayBuffer();
      const decodedBuffer = await audioContext.decodeAudioData(arrayBuffer);
      setBuffer(decodedBuffer);

      if (!previewBuffer) {
        setPreviewBuffer(decodedBuffer);
      }

      setErrorMessage('');
    } catch (error) {
      console.error('Error loading audio file:', error);
      setErrorMessage('Error loading audio file. Please try another file.');
    }
  }, [audioContext, previewBuffer]);

  // Note creation and playback
  const createNote = useCallback((buffer, semitone, startTime, duration, velocity = 1) => {
    if (!audioContext || !buffer || semitone === null || !masterGainNode) return null;

    const source = audioContext.createBufferSource();
    const gainNode = audioContext.createGain();

    source.buffer = buffer;
    source.playbackRate.value = Math.pow(2, semitone / 12) * speed;

    gainNode.gain.setValueAtTime(0, startTime);
    gainNode.gain.linearRampToValueAtTime(velocity * volume, startTime + 0.005);
    gainNode.gain.setValueAtTime(velocity * volume, startTime + duration - 0.005);
    gainNode.gain.linearRampToValueAtTime(0, startTime + duration);

    source.connect(gainNode);
    gainNode.connect(masterGainNode);

    const sourceId = Date.now() + Math.random();
    activeSourcesRef.current[sourceId] = { source, gainNode, semitone };

    source.onended = () => {
      delete activeSourcesRef.current[sourceId];
      setActiveNotes(prev => prev.filter(note => note !== semitone));
      source.disconnect();
      gainNode.disconnect();
    };

    source.start(startTime);
    source.stop(startTime + duration);
    setActiveNotes(prev => [...prev, semitone]);

    return sourceId;
  }, [audioContext, masterGainNode, speed, volume]);

  // Preview note playback
  const playPreviewNote = useCallback((semitone) => {
    if (!audioContext || !previewBuffer) return;

    if (previewSourceRef.current) {
      previewSourceRef.current.stop();
      previewSourceRef.current = null;
    }

    const startTime = audioContext.currentTime;
    createNote(previewBuffer, semitone, startTime, 0.3, 0.8);
  }, [audioContext, previewBuffer, createNote]);

  // ... (previous code remains the same)

  // Sequence playback logic
  const playSequence = useCallback((notes, buffer, startTime) => {
    notes.forEach((note, index) => {
      if (note !== null) {
        const noteStartTime = startTime + index * (delay / 1000);
        const timeout = setTimeout(() => {
          createNote(buffer, note, audioContext.currentTime, delay / 1000);
        }, noteStartTime * 1000);
        activeTimeoutsRef.current.push(timeout);
      }
    });
  }, [delay, createNote, audioContext]);

  // Main playback control
  const playArpeggios = useCallback(() => {
    if (isPlaying || !melodyBuffer || !accompanimentBuffer) return;

    const startPlayback = async () => {
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      setIsPlaying(true);
      const startTime = audioContext.currentTime;

      for (let i = 0; i < repetitions; i++) {
        if (playbackMode === 'simultaneous') {
          playSequence(melodyNotes, melodyBuffer, startTime + i * (melodyNotes.length * delay / 1000));
          playSequence(accompanimentNotes, accompanimentBuffer, startTime + i * (accompanimentNotes.length * delay / 1000));
        } else {
          const melodyDuration = melodyNotes.length * delay / 1000;
          playSequence(
            melodyNotes,
            melodyBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000)
          );
          playSequence(
            accompanimentNotes,
            accompanimentBuffer,
            startTime + i * (melodyDuration + accompanimentNotes.length * delay / 1000) + melodyDuration
          );
        }
      }

      const totalDuration = playbackMode === 'simultaneous'
        ? repetitions * Math.max(melodyNotes.length, accompanimentNotes.length) * delay
        : repetitions * (melodyNotes.length + accompanimentNotes.length) * delay;

      stopPlaybackTimeoutRef.current = setTimeout(() => {
        setIsPlaying(false);
      }, totalDuration);
    };

    startPlayback();
  }, [
    isPlaying,
    melodyBuffer,
    accompanimentBuffer,
    melodyNotes,
    accompanimentNotes,
    delay,
    repetitions,
    playbackMode,
    playSequence,
    audioContext
  ]);

  // Stop all audio
  const stopAllAudio = useCallback(() => {
    // Clear all timeouts
    activeTimeoutsRef.current.forEach(clearTimeout);
    activeTimeoutsRef.current = [];

    if (stopPlaybackTimeoutRef.current) {
      clearTimeout(stopPlaybackTimeoutRef.current);
    }

    // Stop all active sources with quick fadeout
    const fadeOutTime = 0.02;
    const currentTime = audioContext?.currentTime || 0;

    Object.values(activeSourcesRef.current).forEach(({ source, gainNode }) => {
      gainNode.gain.cancelScheduledValues(currentTime);
      gainNode.gain.setValueAtTime(gainNode.gain.value, currentTime);
      gainNode.gain.linearRampToValueAtTime(0, currentTime + fadeOutTime);

      setTimeout(() => {
        source.stop();
        source.disconnect();
        gainNode.disconnect();
      }, fadeOutTime * 1000);
    });

    activeSourcesRef.current = {};
    setActiveNotes([]);
    setIsPlaying(false);
  }, [audioContext]);

  // Note management functions
  const addNote = (type, note) => {
    if (type === 'melody') {
      setMelodyNotes(prev => [...prev, note]);
    } else {
      setAccompanimentNotes(prev => [...prev, note]);
    }
  };

  const removeNote = (type, index) => {
    if (type === 'melody') {
      setMelodyNotes(prev => prev.filter((_, i) => i !== index));
    } else {
      setAccompanimentNotes(prev => prev.filter((_, i) => i !== index));
    }
  };

  // Keyboard event handling
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.repeat) return;
      const semitone = KEY_MAP[event.key.toLowerCase()];
      if (semitone !== undefined) {
        playPreviewNote(semitone);
        addNote(pianoMode, semitone);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [pianoMode, playPreviewNote]);

  // Component render
  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Arpeggio Generator</h1>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </header>

      <div className="main-content">
        {/* File Upload Section */}
        <div className="file-upload-section">
          <div className="upload-container">
            <label className="file-label">
              <span>Melody Audio File (WAV/MP3)</span>
              <input
                type="file"
                accept=".wav,.mp3"
                onChange={(e) => loadAudioFile(e.target.files[0], setMelodyBuffer)}
                className="file-input"
              />
              <span className="file-button">Choose File</span>
            </label>
          </div>

          <div className="upload-container">
            <label className="file-label">
              <span>Accompaniment Audio File (WAV/MP3)</span>
              <input
                type="file"
                accept=".wav,.mp3"
                onChange={(e) => loadAudioFile(e.target.files[0], setAccompanimentBuffer)}
                className="file-input"
              />
              <span className="file-button">Choose File</span>
            </label>
          </div>
        </div>

        {/* Controls Section */}
        <div className="controls-section">
          <div className="control-group">
            <label>
              <span>Delay: {delay}ms</span>
              <input
                type="range"
                min="50"
                max="500"
                value={delay}
                onChange={(e) => setDelay(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Speed: {speed.toFixed(2)}x</span>
              <input
                type="range"
                min="0.5"
                max="2"
                step="0.1"
                value={speed}
                onChange={(e) => setSpeed(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Volume: {Math.round(volume * 100)}%</span>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Repetitions: {repetitions}</span>
              <input
                type="range"
                min="1"
                max="10"
                value={repetitions}
                onChange={(e) => setRepetitions(Number(e.target.value))}
                className="slider"
              />
            </label>
          </div>

          <div className="control-group">
            <label>
              <span>Playback Mode</span>
              <select
                value={playbackMode}
                onChange={(e) => setPlaybackMode(e.target.value)}
                className="select-input"
              >
                <option value="simultaneous">Simultaneous</option>
                <option value="sequential">Sequential</option>
              </select>
            </label>
          </div>
        </div>

        {/* Note Sequences Display */}
        <div className="sequences-section">
          <div className="sequence-display">
            <h3>Melody Sequence</h3>
            <div className="note-list">
              {melodyNotes.map((note, index) => (
                <div key={index} className="note-item">
                  <span>{note !== null ? SEMITONE_TO_NOTE[note][0] : 'Rest'}</span>
                  <button
                    onClick={() => removeNote('melody', index)}
                    className="remove-note"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="sequence-display">
            <h3>Accompaniment Sequence</h3>
            <div className="note-list">
              {accompanimentNotes.map((note, index) => (
                <div key={index} className="note-item">
                  <span>{note !== null ? SEMITONE_TO_NOTE[note][0] : 'Rest'}</span>
                  <button
                    onClick={() => removeNote('accompaniment', index)}
                    className="remove-note"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Piano Section */}
        <div className="piano-section">
          <div className="piano-mode-toggles">
            <label className={`mode-toggle ${pianoMode === 'melody' ? 'active' : ''}`}>
              <input
                type="radio"
                name="pianoMode"
                value="melody"
                checked={pianoMode === 'melody'}
                onChange={(e) => setPianoMode(e.target.value)}
              />
              Melody
            </label>
            <label className={`mode-toggle ${pianoMode === 'accompaniment' ? 'active' : ''}`}>
              <input
                type="radio"
                name="pianoMode"
                value="accompaniment"
                checked={pianoMode === 'accompaniment'}
                onChange={(e) => setPianoMode(e.target.value)}
              />
              Accompaniment
            </label>
          </div>

          <div className="piano">
            {Object.entries(SEMITONE_TO_NOTE).map(([semitone, notes]) => {
              const isBlack = [1, 3, 6, 8, 10].includes(Number(semitone));
              return (
                <div
                  key={semitone}
                  className={`piano-key ${isBlack ? 'black' : 'white'} 
                             ${activeNotes.includes(Number(semitone)) ? 'active' : ''}`}
                  onClick={() => {
                    playPreviewNote(Number(semitone));
                    addNote(pianoMode, Number(semitone));
                  }}
                >
                  <span className="note-name">{notes[0]}</span>
                  <span className="key-shortcut">
                    {Object.entries(KEY_MAP).find(([_, value]) => value === Number(semitone))?.[0]}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        {/* Control Buttons */}
        <div className="button-section">
          <button
            className={`control-button play ${isPlaying ? 'playing' : ''}`}
            onClick={playArpeggios}
            disabled={isPlaying || !melodyBuffer || !accompanimentBuffer}
          >
            {isPlaying ? 'Playing...' : 'Play Arpeggio'}
          </button>

          <button
            className="control-button stop"
            onClick={stopAllAudio}
            disabled={!isPlaying}
          >
            Stop
          </button>
        </div>
      </div>

      {/* Styles */}
      <style jsx>{`
        /* ... I'll continue with the styles in the next part ... */
      `}</style>
    </div>
  );
};

export default ArpeggioGenerator;